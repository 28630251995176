<template>
  <b-container fluid>
    <bo-page-title />
    <section id="career">
      <bo-card title="Job Applicants" use-table v-if="isList">
        <b-card-body>
          <b-row>
            <b-col md="2">
              <div class="input-group">
                <datepicker  input-class="form-control transparent" :value="filter.startDate" placeholder="Start Date" @input="filter.startDate = fixDate($event),doFilterDate()"></datepicker>
                <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                </div>
              </div>
            </b-col>
            
            <b-col md="2">
              <div class="input-group">
                <datepicker input-class="form-control transparent" :value="filter.endDate" placeholder="End Date"  @input="filter.endDate = fixDate($event),doFilterDate()" :disabledDates="{ to: new Date(filter.startDate) }"></datepicker>
                <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                </div>
              </div>
            </b-col>
            <b-col md="2">
              <v-select :options="mrCareer" v-model="filter.pos" @input="doFilter()" :clearable="true" :reduce="v => String(v.value)" />
            </b-col>
            <b-col md>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFilter" />
            </b-col>
          </b-row>
        </b-card-body>
        <b-table
          class="mb-custom-7"
          :fields="applicantFields"
          :items="dataList||[]"
          show-empty
          :busy="!dataList"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(num)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}                
          </template>
          <template #cell(name)="data">
            <div>{{ data.item.aca_fullname }}</div>
            <small class="d-inline-block">
              <i class="ti-email mr-1"></i>
              <span>{{ data.item.aca_email }}</span>
            </small><br/>
            <small class="d-inline-block">
              <i class="icon-phone mr-1"></i>
              <span>{{ data.item.aca_phone }}</span>
            </small>
          </template>
          <template #cell(applyFor)="data">
            <div>{{ data.item.ac_position_id }}</div>
          </template>
          <template #cell(cv)="data">
            <div class="wrap_pdf"><a :href="uploaderAssets(data.item.aca_cv)" target="_blank"> Preview CV</a></div>
          </template>
          <template #cell(date)="data">
            <div>{{ data.item.aca_created_date | moment('LLL') }}</div>          
          </template>
          <template #cell(status)="data">
            <b-badge variant="success" v-if="data.item.aca_status == 'Y'">Seen</b-badge>
            <b-badge variant="warning" v-if="data.item.aca_status == 'N'">Have Not Seen</b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="outline-info"
              class="btn-icon"
              v-b-tooltip.hover="'View Details'"
              v-if="moduleRole('detail')"
              @click="detailApply(data.item)"
            ><i class="icon-eye"></i></b-button>

            <a :href="uploaderAssets(data.item.aca_cv)" download target="_blank">
            <b-button
              variant="outline-info"
              class="ml-2 btn-icon"
              v-b-tooltip.hover="'Download'"
            ><i class="ti-download"></i></b-button>
            </a>

          </template>
          <template #empty>
              <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys(filter).length">There is No Data</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
              </div>
          </template>
        </b-table>
        <template #footer>
          <b-pagination
            v-if="data.total > data.per_page"
            class="mb-0 justify-content-end"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </template>
      </bo-card>
      <b-modal
				id="applyDetail"
				title="Detail Apply"
				centered
				no-close-on-backdrop
			>
				<b-row class="gx-0 gy-2">
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Name</strong>
								<span>{{row.aca_fullname}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Phone Number</strong>
								<span>{{row.aca_phone}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Email</strong>
								<span>{{row.aca_email || '-'}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Position</strong>
								<span>{{row.ac_position_id}}</span>
							</div>
						</div>
					</b-col>
					<b-col cols="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>CV</strong>
                <a :href="uploaderAssets(row.aca_cv)" download target="_blank"> Preview CV</a>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>IP</strong>
                <span>{{ row.aca_ip }}</span>
							</div>
						</div>  
					</b-col>
					<b-col md=12 v-if="row.aca_created_date">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Date</strong>
								<p v-if="row.aca_created_date">Submited at: {{ row.aca_created_date | moment('LLLL') }}</p>
								<p v-if="row.aca_seen_date">Seen at: {{ row.aca_seen_date | moment('LLLL') }}</p>
							</div>
						</div>
					</b-col>
				</b-row>
				<template #modal-footer>
					<b-button variant="primary" @click.prevent="closeModalDetail">Close</b-button>
				</template>
			</b-modal>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'
import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue, 
  components: {
    Datepicker
  },
  data() {
    return {
      currentPage: 1,

      applicantFields: [
        { key: 'num', label: '#' },
        { key: 'name', label: 'Applicant\'s Name' },
        'applyFor',
        { key: 'cv', label: 'CV' }, 
        {key: 'date', label: 'Submit Date'},
        'status',
        'actions'
      ],
      mrCareer: [],
      val : 'all',
      seoRow: {},
      heroEditorOpen: false,
      isFound : true
    }
  },
  computed:{
    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", 50)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        }).catch(()=>{})
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.$route.query.shown||this.filter.shown||50
      },
      // setter
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        }).catch(()=>{})
      }
    },
  },
  methods: {
    detailApply(item){
      this.row = _.clone(item)
      if(this.row.aca_status == 'N'){
        this.loadingOverlay = true
        Gen.apiRest("/do/"+ this.modulePage,
          { data:{ type:'seen', id: item.aca_id}}, 
          "POST"
        ).then(() => {
          this.loadingOverlay = false
          this.$bvModal.show('applyDetail')
        }).catch(() => {
          this.loadingOverlay = false
          this.$bvModal.show('applyDetail')
        })
      }else{
        this.$bvModal.show('applyDetail')
      }
    },
    closeModalDetail(){
      this.row = {}
      this.$bvModal.hide('applyDetail')
    },
    apiGet(params = {}){
      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      if(params.slugs) slugs = params.slugs
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        if(!this.isFound){
          this.filter['pos'] = 'all'
        }
        if(!this.isList){
          this.setFocusForm()
        }
        setTimeout(()=>{
          $('.label_error').hide()
        },1000)
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    doReset(){
      const filterKeys = Object.keys(this.filter)
      const querys = _.assign(_.omit(this.$route.query||{}, filterKeys), {page:1})

      this.filter={}
      this.$router.push({name:this.$route.name,query:querys}).catch(()=>{})
      this.filter['pos'] = 'all'
    },
    fixDate(event){
        return this.$moment(event).format('YYYY-MM-DD')
    },
    doFilterDate(){
        if(this.filter.startDate && this.filter.endDate){
            this.doFilter()
        }
    }
  },
	mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.apiGet()
    })
		this.apiGet()
    if(!this.$route.query.pos){
      this.filter['pos'] = 'all'
    } 
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>